import { Facebook, Google } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import GoogleLogin from "react-google-login";
import { useDispatch } from "react-redux";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import LoadingDialog from "../utils/LoadingDialog";
import FeedbackDialog from "../utils/FeedbackDialog";
import { makeStyles } from "@mui/styles";
import { DATA_KEY, googleWebClientID, legalContracts } from "../utils/ImiData";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import {
  useGetUserProfileMutation,
  useGetUserTokenFBMutation,
  useGetUserTokenGoogleMutation,
} from "../services/user";
import { homeActions } from "../store/store";
import { getElementWidth } from "../utils/ScreenManager";

const LoginDialog = ({ onClose, open }) => {
  //#region STYLES
  const useStyles = makeStyles(() => ({}));
  //#endregion

  //#region HOOKS & APIS

  const dispatch = useDispatch();
  const loginToken = useRef(null);

  //USER TOKEN FB
  const [
    getUserTokenFB,
    {
      isLoading: loadingFBTokenAPI,
      isSuccess: fbTokenAPISuccess,
      data: fbTokenAPIData,
      error: fbTokenAPIError,
    },
  ] = useGetUserTokenFBMutation();

  //USER TOKEN FB
  const [
    getUserTokenGoogle,
    {
      isLoading: loadingGoogleTokenAPI,
      isSuccess: googleTokenAPISuccess,
      data: googleTokenAPIData,
      error: googleTokenAPIError,
    },
  ] = useGetUserTokenGoogleMutation();

  //USER PROFILE
  const [
    getUserProfile,
    {
      isLoading: loadingUserProfileAPI,
      isSuccess: userProfileAPISuccess,
      data: userProfileAPIData,
      error: userProfileAPIError,
    },
  ] = useGetUserProfileMutation();

  useEffect(() => {
    if (fbTokenAPISuccess || googleTokenAPISuccess) {
      dispatch(homeActions.setUserToken(loginToken.current));
      localStorage.setItem(DATA_KEY, loginToken.current);
      onClose(true);
    }
  }, [fbTokenAPISuccess, googleTokenAPISuccess]);
  //#endregion

  //#region UTIL

  const responseFacebook = (response) => {
    //console.log(response);

    if (response.accessToken) {
      setTokenAPIStateIndex(1); //GET FB USER TOKEN
      getUserTokenFB(response.accessToken);
    } else {
      setTokenAPIStateIndex(4);
    }
  };

  const responseGoogle = (response) => {
    //console.log(response);

    if (response.tokenId) {
      setTokenAPIStateIndex(2); //GET GOOGLE USER TOKEN
      getUserTokenGoogle(response.tokenId);
    } else {
      setTokenAPIStateIndex(4);
    }
  };

  const termsCond_BtnClick = () => {
    //onLoginDialogActionBtnClick();
    //REFRESH IFRAME WITH GAME URL

    // dispatch(
    //   homeActions.setActiveUrlData({
    //     isGame: false,
    //     url: legalContracts.termsCondUrl,
    //   })
    // );

    window.open(legalContracts.termsCondUrl);
  };

  const privacyPolicy_BtnClick = () => {
    //onLoginDialogActionBtnClick();
    //REFRESH IFRAME WITH GAME URL
    // dispatch(
    //   homeActions.setActiveUrlData({
    //     isGame: false,
    //     url: legalContracts.termsCondUrl,
    //   })
    // );
    window.open(legalContracts.privacyPolicyUrl);
  };

  //#endregion

  //#region USER TOKEN APIS STATES

  const [tokenAPIStateIndex, setTokenAPIStateIndex] = useState(0);

  let tokenAPIStateContent;

  //FB TOKEN
  if (tokenAPIStateIndex === 1) {
    if (loadingFBTokenAPI) {
      tokenAPIStateContent = <LoadingDialog />;
    } else if (fbTokenAPIError) {
      tokenAPIStateContent = (
        <FeedbackDialog
          open={true}
          onClose={() => {
            setTokenAPIStateIndex(0);
          }}
          data={{
            title: "Oops!",
            msg: "FB Error",
          }}
        />
      );
    } else if (fbTokenAPISuccess) {
      loginToken.current = fbTokenAPIData.access_token;
    }
  }
  //GOOGLE TOKEN
  else if (tokenAPIStateIndex === 2) {
    if (loadingGoogleTokenAPI) {
      tokenAPIStateContent = <LoadingDialog />;
    } else if (googleTokenAPIError) {
      //console.log(googleTokenDataError.error);

      tokenAPIStateContent = (
        <FeedbackDialog
          open={true}
          onClose={() => {
            setTokenAPIStateIndex(0);
          }}
          data={{
            title: "Oops!",
            msg: "Google Error",
          }}
        />
      );
    } else if (googleTokenAPISuccess) {
      loginToken.current = googleTokenAPIData.access_token;
    }
  }
  //USER TOKEN
  else if (tokenAPIStateIndex === 3) {
    if (loadingUserProfileAPI) {
      tokenAPIStateContent = <LoadingDialog />;
    } else if (userProfileAPIError) {
      tokenAPIStateContent = (
        <FeedbackDialog
          id="Token Error Dialog"
          open={true}
          onClose={() => {
            setTokenAPIStateIndex(0);
          }}
          data={{
            title: "Oops!",
            msg: "Error",
          }}
        />
      );
    } else if (userProfileAPISuccess) {
      setTokenAPIStateIndex(0);
    }
  }
  //FEEDBACK DIALOG
  else if (tokenAPIStateIndex === 4) {
    tokenAPIStateContent = (
      <FeedbackDialog
        open={true}
        onClose={() => {
          setTokenAPIStateIndex(0);
        }}
        data={{
          title: "Oops!",
          msg: "User Cancelled",
        }}
      />
    );
  } else if (tokenAPIStateIndex === 5) {
    tokenAPIStateContent = null;
  }
  //LOGIN DIALOG
  else {
    tokenAPIStateContent = (
      <Fragment>
        <Dialog fullWidth={true} open={open}>
          <DialogTitle>
            <Typography
              style={{
                fontSize: getElementWidth(35),
                textAlign: "left",
                fontWeight: "100",
              }}
            >
              Please login to continue!
            </Typography>
          </DialogTitle>
          <DialogContent dividers>
            <FacebookLogin
              appId="421341401639640"
              autoLoad={false}
              callback={responseFacebook}
              render={(renderProps) => (
                <Button
                  onClick={renderProps.onClick}
                  style={{
                    backgroundColor: "rgba(75, 111, 167,255)",
                    display: "flex",
                    flexDirection: "row",
                    width: getElementWidth(480),
                    height: getElementWidth(80),
                    //display : 'flex',
                    marginTop: getElementWidth(20),
                    //marginBottom : getElementWidth(20),
                    marginLeft: "auto",
                    marginRight: "auto",
                    justifyContent: "center",
                  }}
                  startIcon={<Facebook sx={{ color: "white" }} />}
                >
                  <Typography
                    color="white"
                    fontSize={getElementWidth(28)}
                    fontWeight="bold"
                    textTransform="none"
                  >{`Facebook`}</Typography>
                </Button>
              )}
              isMobile={true}
              disableMobileRedirect={true}
            />

            <GoogleLogin
              clientId={googleWebClientID}
              render={(renderProps) => (
                <Button
                  onClick={renderProps.onClick}
                  style={{
                    backgroundColor: "rgba(214, 72, 34,255)",
                    display: "flex",
                    flexDirection: "row",
                    width: getElementWidth(480),
                    height: getElementWidth(80),
                    //display : 'flex',
                    marginTop: getElementWidth(20),
                    //marginBottom : getElementWidth(20),
                    marginLeft: "auto",
                    marginRight: "auto",
                    justifyContent: "center",
                  }}
                  startIcon={<Google sx={{ color: "white" }} />}
                >
                  <Typography
                    color="white"
                    fontSize={getElementWidth(28)}
                    fontWeight="bold"
                    textTransform="none"
                  >{`Google`}</Typography>
                </Button>
              )}
              buttonText="Login"
              autoLoad={false}
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              //cookiePolicy={'single_host_origin'}
            />

            <div style={{ height: getElementWidth(40) }}></div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <Typography
                style={{
                  textTransform: "none",
                  fontSize: getElementWidth(20),
                  textAlign: "center",
                  fontWeight: "500",
                }}
              >
                By Logging in, you agree to our&nbsp;
                <span
                  style={{ color: "rgba(206, 60, 54,255)" }}
                  onClick={termsCond_BtnClick}
                >
                  Terms and Conditions{" "}
                </span>
                and acknowledge that you have read the
                <span
                  style={{ color: "rgba(206, 60, 54,255)" }}
                  onClick={privacyPolicy_BtnClick}
                >
                  {" "}
                  Privacy Policy.
                </span>
              </Typography>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => onClose(false)} color="primary">
              <Typography
                style={{ fontSize: getElementWidth(30), textAlign: "left" }}
              >
                Cancel
              </Typography>
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
  //#endregion

  return <Fragment>{tokenAPIStateContent}</Fragment>;
};

export default LoginDialog;
