export const baseUrl = "https://api.staging.imigames.io:443/api/v1";
//export const baseUrl = "https://api.imigames.io:443/api/v1";

export const googleWebClientID =
  "356024293196-ma8od8hv9nuhrqiu3n7ms8cb4is7f8ck.apps.googleusercontent.com";

export const legalContracts = {
  termsCondUrl:
    "https://imigames.s3.ap-southeast-1.amazonaws.com/economynext/terms%26condition/index.html",
  privacyPolicyUrl:
    "https://imigames.s3.ap-southeast-1.amazonaws.com/economynext/privacy_policy/index.html",
};

export const DATA_KEY = "SUDOKU_USER_DATA";
export const GAME_ID = "58f61b02-08d1-42f4-a06c-57eee04d8b41";
