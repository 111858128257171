const GameFrame = ({ gameUrl, inGameLoginBool, token }) => {
  let url = `${gameUrl}?platform=pwa&version=200`;

  if (token) {
    url += `&user=${token}`;
  }

  if (inGameLoginBool) {
    url += `&ingamelogin=true`;
  }
  //console.log(url);

  return (
    <iframe
      id="gameIframe"
      title="gameFrame"
      //src="game/index.html?user=eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJLYWxhbmEiLCJhdXRob3JpdGllcyI6WyJST0xFX0RFViJdLCJpYXQiOjE2MjAzNzcxODEsImV4cCI6MTY1MTkxMzE4MSwidWQiOiI1ZDFjOTU5OS1mY2M3LTQ2ZDMtYmQ2YS1hZjhiMmJmNjQxOWUiLCJ0eXBlIjoiQUNDRVNTIn0.gsQbBX3ALI9dKSD2qMDcw18-z-AQu_WHvBjxqHVdzxM&amp;language=ENGLISH&amp;platform=pwa&amp;isWebview=false"
      src={url}
      allow="microphone; accelerometer; encrypted-media"
      style={{
        touchAction:'auto',
        maxWidth: "480px",
        width:"100%",
        height: "90%",
        border: "0",
        position: "fixed",
        top: "0",
        left: "50%",
        // overflow: "hidden",
        transform: "translateX(-50%)",
        // margin: "auto",
      }}
    ></iframe>
  );
};

export default GameFrame;
