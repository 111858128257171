import { LoginRounded } from "@mui/icons-material";
import { Fragment, useRef, useState } from "react";
import { useEffect } from "react";
import GameFrame from "./components/GameFrame";
import LoginDialog from "./components/LoginDialog";
import { useWindowSize } from "./hooks/useWindowSize";
import LoadingDialog from "./utils/LoadingDialog";
import { screenSizeData } from "./utils/ScreenManager";
import { useGetGameDetailsQuery } from "./services/game";
import { DATA_KEY, GAME_ID } from "./utils/ImiData";
import { Backdrop, CircularProgress } from "@mui/material";
import FeedbackDialog from "./utils/FeedbackDialog";
import { useDispatch, useSelector } from "react-redux";
import { homeActions } from "./store/store";

function App() {
  //#region HOOKS
  [screenSizeData.currentScreenWidth, screenSizeData.currentScreenHeight] =
    useWindowSize();

  const dispatch = useDispatch();

  const inGameLogBoolRef = useRef(false);

  const [openLoginDialog, setOpenLoginDialog] = useState(false);

  useEffect(() => {
    const eventListener = window.addEventListener(
      "message",
      (event) => {
        if (typeof event.data === "string") {
          //CHECK FOR JSON OBJECT
          try {
            console.log(event.data);
            let commandObj = JSON.parse(event.data);

            if (commandObj.command === "HIDE_NAV") {
            } else if (commandObj.command === "EXIT") {
              window.open("https://app.imigames.io");
            } else if (commandObj.command === "SUBSCRIBE") {
            } else if (commandObj.command === "LOGOUT") {
              inGameLogBoolRef.current = false;
              localStorage.removeItem(DATA_KEY);
              dispatch(homeActions.setUserToken(null));
            } else if (commandObj.command === "LOGIN") {
              setOpenLoginDialog(true);
            }
          } catch (e) {
            //console.log("JSON Parse Error");
          }
        }
      },
      false
    );

    return () => {
      window.removeEventListener("message", eventListener);
    };
  }, []);

  const userToken = useSelector((state) => state.home.userToken);

  useEffect(() => {
    //RETRIEVE DATA ON PAGE REFRESH
    if (userToken === null) {
      let _token = localStorage.getItem(DATA_KEY);

      if (_token) {
        dispatch(homeActions.setUserToken(_token));
      }
    }
  }, []);
  //#endregion

  //#region LOGIN DIALOG

  const onLogInDialogActionBtnClick = (logInBool = false) => {
    if (logInBool) {
      inGameLogBoolRef.current = true;
    } else {
      document.getElementById("gameIframe").src += "";
    }

    setOpenLoginDialog(false);
  };

  let loginDialogContent;

  if (openLoginDialog) {
    loginDialogContent = (
      <LoginDialog
        onClose={onLogInDialogActionBtnClick}
        open={true}
      ></LoginDialog>
    );
  } else {
    loginDialogContent = null;
  }
  //#endregion

  //#region GET GAME URL
  let getGameDetailsAPIContent;

  const {
    isLoading: isLoadingGetGameDetailsAPI,
    isSuccess: isSuccessGameGameDetailsAPI,
    data: getGameDetailsAPIData,
    error: getGameDetailsAPIError,
  } = useGetGameDetailsQuery({ uuid: GAME_ID });

  if (isLoadingGetGameDetailsAPI) {
    getGameDetailsAPIContent = (
      <Backdrop
        open={true}
        sx={{
          backgroundColor: "black",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <CircularProgress sx={{ color: "blue" }} />
      </Backdrop>
    );
  } else if (isSuccessGameGameDetailsAPI) {
    //console.log(getGameDetailsAPIData);
    getGameDetailsAPIContent = (
      <GameFrame
        gameUrl={getGameDetailsAPIData?.url}
        inGameLoginBool={inGameLogBoolRef.current}
        token={userToken}
      ></GameFrame>
    );
  } else if (getGameDetailsAPIError) {
    getGameDetailsAPIContent = (
      <FeedbackDialog
        open={true}
        onClose={() => {
          window.location.reload();
        }}
        data={{
          title: "Oops!",
          msg: "Something Went Wrong",
        }}
      />
    );
  } else {
    getGameDetailsAPIContent = null;
  }
  //#endregion

  return (
    <Fragment>
      {getGameDetailsAPIContent}
      {loginDialogContent}
    </Fragment>
  );
}

export default App;
